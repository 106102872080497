export const errors = {
  cities: 'Не удалось загрузить список городов',
  chains: 'Не удалось загрузить список сетей магазинов',
  stores: 'Не удалось загрузить список магазинов',
  roles: 'Не удалось загрузить список ролей',
  clothingSize: 'Не удалось загрузить список размеров одежды',
  reasonsTitle: 'Требуется уточнение данных.',
  commentTitle: 'Комментарий',
  bankAccount: 'У ИП номер расчетного счета должен начинаться с “40802“',
  bankAccountSelf: 'Номер расчетного счета не соответствует типу самозанятый',
  bankBikAccount: 'Расчетный счет указан неверно — отсутствует в данном банке (БИК)',
  innCheckSum: 'Некорректный ИНН',
  snilsCheckSum: 'Некорректный СНИЛС',
  common: 'Ошибка доступа к данным',
  oldDate: 'Дата просрочена',
  futureDate: 'Дата не должна быть больше текущей',
  documentsUploading: 'Ошибка: не все документы загрузились',
  acceptLmkAgreement: 'Ошибка подтверждения согласия на обработку персональных данных (ЛМК)',
  nameRus: 'Только кириллица, дефис'
}

export const adminValidationErrors = {
  inn: 'Пожалуйста, введите 12 цифр ИНН партнера',
  snils: 'Пожалуйста, введите 11 цифр СНИЛС партнера',
  bankAccount: 'Пожалуйста, введите номер расчетного счета, состоящий из 20 цифр и начинающийся с “408”',
  bankBik: 'Пожалуйста, введите БИК банка, состоящий из 9 цифр и начинающийся с “04”.',
  bankName: 'Пожалуйста, укажите название банка, используйте русские/английские буквы и символы \'., - №',
  uid: 'Только цифры или буквы'
}
