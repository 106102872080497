import React, { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef } from 'react'
import { Layout, Button, Form, Input, Typography, Spin, message, Col, Row } from 'antd'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { PatternFormat } from 'react-number-format'

import MobileHeader from '../../../components/Partner/MobileHeader/MobileHeader'
import './AuthScreenStyle.css'
import {
  PROFILE_MAIN_PAGE,
  ELECTRONIC_SIGNATURE_POLICY_PAGE,
  PERSONAL_DATA_POLICY_PAGE,
  PARTNERS_SBER_MARKET_POLICY_PAGE
} from '../../../routes'
import { useStores } from '../../../Store/Store'
import { authScreen } from '../../../translates'
import { apiErrorCodes, AppConfig } from '../../../AppConfig'
import { personalPolicyDocLink } from '../../../Constants'
import ModalAuthUuid from '../../../components/Partner/ModalAuthUuid/ModalAuthUuid'
import AuthSberId from './AuthSberId'
import { antdInputHandleChange, isFlowV2, isMobile, patterns, simpleReducer } from '../../../helper'
import AuthTinkoffId from './AuthTinkoffId'
import { useMutateAuthPartner, useMutateAuthVerifyPartner } from '../../../api/react-query/partners'
import ModalDrawerPopup from '../../../components/Common/ModalDrawerPopup/ModalDrawerPopup'

const { Content } = Layout
const { Title, Text } = Typography

const SMS_LIMIT_LEN = 6

// const layout = {
//   wrapperCol: { xs: { span: 18, offset: 3 }, sm: { span: 18, offset: 3 } },
//   labelCol: { xs: { span: 18, offset: 3 }, sm: { span: 18, offset: 3 } }
// }
const isAndroid = /android/i.test(navigator.userAgent || navigator.vendor || window.opera)

const inputModes = {
  inputPhone: 'inputPhone',
  inputSms: 'inputSms'
}

const initialState = {
  profile: false,
  timeState: {},
  isModalAuthUuid: false,
  isModalPhone: false,
  mobilePhone: '',
  smsCode: '',
  smsCodeError: null,
  inputMode: inputModes.inputPhone,
  isVerifySms: false
}

const AuthScreen = () => {
  const location = useLocation()
  const store = useStores()
  const [form] = Form.useForm()
  const timerRef = useRef()
  const inputPhoneRef = useRef()
  const inputSmsRef = useRef()
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleChange = useCallback(
    event => {
      // https://github.com/s-yadav/react-number-format/issues/818#issuecomment-2067805933
      antdInputHandleChange(event.currentTarget, inputPhoneRef)
    },
    []
  )

  const phoneFromCode = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const phoneBase64 = params.get('code')
    let phone
    try {
      phone = phoneBase64 ? atob(phoneBase64) : null
    } catch (e) {}
    return phone
  }, [location.search])

  const isSelfEmployedPartner = useMemo(() => {
    return location.search === `?${AppConfig.urlRoleCodes.selfEmployed}`
  }, [location.search])

  const isSelfEmployedResearcher = useMemo(() => {
    return location.search === `?${AppConfig.urlRoleCodes.selfEmployedResearcher}`
  }, [location.search])

  const isSelfEmployedOperator = useMemo(() => {
    return location.search === `?${AppConfig.urlRoleCodes.selfEmployedOperator}`
  }, [location.search])

  const isTinkoffId = useMemo(() => {
    return !isSelfEmployedPartner && !isSelfEmployedResearcher && !isSelfEmployedOperator
  }, [isSelfEmployedPartner, isSelfEmployedResearcher, isSelfEmployedOperator])

  const isExternalBankDetails = useMemo(() => {
    return location.search === `?${AppConfig.urlParamCodes.externalBankDetails}`
  }, [location.search])

  useEffect(() => {
    if (location.search === `?${AppConfig.urlParamCodes.flowV2}`) {
      localStorage.setItem(AppConfig.partnerFlow.localStoreName, AppConfig.partnerFlow.v2)
    }
  }, [location.search])

  const {
    mutate: sendSmsCode,
    data: authData,
    isSuccess: isSuccessAuthData,
    isError: isErrorAuthData,
    isLoading: isLoadingAuthData
  } = useMutateAuthPartner()

  const onSendSmsCode = () => {
    // валидируем маску - все ли цифры введены
    if (form.getFieldValue('phoneNumber')?.includes('_') || !form.getFieldValue('phoneNumber')) {
      if (isMobile()) {
        inputPhoneRef?.current?.input?.focus()
      }
      return form.setFields([{
        name: 'phoneNumber',
        errors: [authScreen.input.phoneNumber.message]
      }])
    }
    form.validateFields(['phoneNumber']).then(() => {
      setState({
        smsCodeError: null
      })

      // if (!isAndroid && isMobile()) {
      //   inputSmsRef?.current?.input?.focus()
      // }
      sendSmsCode({
        mobilePhone: form.getFieldValue('phoneNumber').replace(/[ _-]/g, ''),
        ...(isSelfEmployedPartner && { role: AppConfig.partnerRoles.szContent }),
        ...(isSelfEmployedResearcher && { role: AppConfig.partnerRoles.researcher }),
        ...(isSelfEmployedOperator && { role: AppConfig.partnerRoles.szOperator }),
        ...(isExternalBankDetails && { externalBankDetails: true })
      })
    }).catch(() => {})
  }

  useEffect(() => {
    if (isSuccessAuthData && authData.isSuccess && authData?.retryAfter === undefined) {
      const retryAfter = 59
      setState({
        inputMode: inputModes.inputSms,
        timeState: {
          retryAfter: retryAfter,
          time: prepareTime(retryAfter)
        }
      })
    } else if (!authData?.isSuccess && authData?.retryAfter >= 0) {
      setState({
        timeState: {
          retryAfter: authData.retryAfter,
          time: prepareTime(authData.retryAfter)
        }
      })
    } else if (!authData?.isSuccess && authData?.errorCode === apiErrorCodes.partnerUuidNotFound) {
      setState({ isModalAuthUuid: true })
    } else if (isErrorAuthData || authData?.errorMessage) {
      message.error(authData?.errorMessage || 'Ошибка получения данных телефона')
    }
  }, [authData, isSuccessAuthData, isErrorAuthData])

  const {
    mutate: verifySms,
    data: verifyData,
    isSuccess: isSuccessVerify,
    isError: isErrorVerify,
    isLoading: isLoadingVerify
  } = useMutateAuthVerifyPartner()

  const onFinish = values => {
    if (state.inputMode !== inputModes.inputSms) {
      return
    }
    if (state.isVerifySms) {
      return
    }
    if (values.smsCode?.length === SMS_LIMIT_LEN) {
      setState({
        isVerifySms: true
      })
      verifySms({
        code: values.smsCode,
        mobilePhone: state.mobilePhone,
        ...(isSelfEmployedPartner && { role: AppConfig.partnerRoles.szContent }),
        ...(isSelfEmployedResearcher && { role: AppConfig.partnerRoles.researcher }),
        ...(isSelfEmployedOperator && { role: AppConfig.partnerRoles.szOperator }),
        ...(isExternalBankDetails && { externalBankDetails: true }),
        ...(isFlowV2() && { requestedFlow: AppConfig.partnerFlow.v2 })
      })
    }
  }

  useEffect(() => {
    if (isSuccessVerify && verifyData.isSuccess) {
      store.partnerProfile.setPartnerProfile(verifyData.partner)
      setState({ profile: verifyData.partner.profile, isVerifySms: false })
      localStorage.removeItem(AppConfig.partnerFlow.localStoreName)
    } else if (!verifyData?.isSuccess && verifyData?.errorMessage) {
      setState({ smsCodeError: verifyData?.errorMessage, isVerifySms: false })
    } else if (!verifyData?.isSuccess && verifyData?.errorCode === apiErrorCodes.partnerUuidNotFound) {
      setState({ isModalAuthUuid: true, isVerifySms: false })
    } else if (isErrorVerify || verifyData?.errorMessage) {
      setState({ isVerifySms: false })
      message.error(verifyData?.errorMessage || 'Ошибка получения данных смс кода')
    }
  }, [verifyData, isSuccessVerify, isErrorVerify])

  useEffect(() => {
    if (state.timeState.time) {
      timerRef.current = setTimeout(() => {
        if (state.timeState.time === '00:01') {
          setState({ timeState: {} })
        } else {
          const newTime = state.timeState.retryAfter - 1
          setState({
            timeState: {
              time: prepareTime(newTime),
              retryAfter: newTime
            }
          })
        }
      }, 1000)
    }
  }, [state.timeState])

  const prepareTime = seconds => {
    const date = new Date(0)
    date.setSeconds(seconds) // specify value for SECONDS here
    return date.toISOString().substr(14, 5)
  }
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const validatePhone = (rule, value) => {
    let newValue = value
    const phonePattern = patterns.phone
    if (value?.includes('_') || !value) {
      // пока в маске не введены все цифры не валидируем и не показываем ошибку
      return Promise.resolve()
    }
    if (value[0] !== '+') {
      // при вставке из автоподстановки нет префикса +7
      newValue = '+7' + value
    }
    return value && phonePattern.test(newValue.replace(/[ _-]/g, '')) ? Promise.resolve() : Promise.reject(authScreen.input.phoneNumber.message)
  }

  const onFieldsChange = (fields) => {
    const field = fields[0]
    const phonePattern = /\+7[0-9]{10}$/
    if (field?.name?.[0] === 'phoneNumber' && field?.value && phonePattern.test(field?.value?.replace(/[ _-]/g, ''))) {
      setState({ mobilePhone: field?.value.replace(/[ _-]/g, '') })
    }
    if (field?.name?.[0] === 'smsCode') {
      setState({ smsCode: field?.value.trim(), smsCodeError: null })
    }
  }

  const docParams = useMemo(() => {
    if (isSelfEmployedResearcher) {
      return `?role=${AppConfig.partnerRoles.researcher}`
    }
    if (isSelfEmployedPartner) {
      return `?role=${AppConfig.partnerRoles.szContent}`
    }
    if (isSelfEmployedOperator) {
      return `?role=${AppConfig.partnerRoles.szOperator}`
    }
    return ''
  }, [isSelfEmployedResearcher, isSelfEmployedPartner, isSelfEmployedOperator])

  useEffect(() => {
    if (phoneFromCode) {
      setState({
        mobilePhone: phoneFromCode,
        isModalPhone: true,
        inputMode: inputModes.inputPhone
      })
      form.setFieldsValue({ phoneNumber: phoneFromCode })
      onSendSmsCode()
    }
  }, [phoneFromCode])

  useEffect(() => {
    if (state.smsCode?.length === SMS_LIMIT_LEN && state.inputMode === inputModes.inputSms) {
      form.submit()
    }
  }, [state.smsCode, state.inputMode])

  const isDisabledPhone = useMemo(() => {
    return phoneFromCode && patterns.phone.test(phoneFromCode)
  }, [phoneFromCode])

  const isShowSberAuth = useMemo(() => {
    return !isSelfEmployedPartner && !isSelfEmployedResearcher && !isSelfEmployedOperator
  }, [isSelfEmployedPartner, isSelfEmployedResearcher, isSelfEmployedOperator])

  const onClickByPhone = () => {
    clearTimeout(timerRef.current)
    setState({
      ...initialState,
      ...(isDisabledPhone ? { mobilePhone: phoneFromCode } : {}),
      isModalPhone: true
    })
    if (!isDisabledPhone) {
      form.resetFields()
    }
  }

  const onCloseByPhone = () => {
    clearTimeout(timerRef.current)
    setState({
      ...initialState,
      ...(isDisabledPhone ? { mobilePhone: phoneFromCode } : {}),
      isModalPhone: false
    })
    if (!isDisabledPhone) {
      form.resetFields()
    }
  }

  useLayoutEffect(() => {
    if (state.isModalPhone) {
      if (state.inputMode === inputModes.inputSms) {
        if (isAndroid) {
          setTimeout(() => {
            inputSmsRef?.current?.focus()
          }, 10)
        } else {
          inputSmsRef?.current?.input?.focus()
        }
      }
      if (state.inputMode === inputModes.inputPhone) {
        if (isAndroid) {
          setTimeout(() => {
            inputPhoneRef?.current?.input?.focus()
          }, 10)
        } else {
          inputPhoneRef?.current?.input?.focus()
        }
      }
    }
  }, [state.isModalPhone, state.inputMode])

  const handleClickByInputForScroll = () => {
    setTimeout(() => {
      if (inputPhoneRef?.current) {
        // window.scrollTo(0, document.getElementsByClassName('ant-drawer-content')?.[0].scrollHeight)
        document.getElementsByClassName('ant-drawer-content')?.[0]?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    }, 200)
  }

  return (
    <Layout className='Auth'>
      <MobileHeader />
      {state.profile && <Redirect to={PROFILE_MAIN_PAGE} />}

      <Layout>
        <Content>
          <Col>
            <Form>
              <Title level={4}>{authScreen.title}</Title>
              {
                isShowSberAuth && (
                  <Form.Item>
                    <AuthSberId mobilePhone={state.mobilePhone} type={AppConfig.sberId.buttonType.auth} />
                  </Form.Item>
                )
              }
              {
                isTinkoffId && (
                  <Form.Item>
                    <AuthTinkoffId />
                  </Form.Item>
                )
              }
              {
                (isShowSberAuth || isTinkoffId) && <div className='etcBlock'>или</div>
              }
              <Form.Item>
                <Button
                  className={`enter ${isShowSberAuth && 'withSberId'}`}
                  block
                  size='large'
                  type='primary'
                  onClick={onClickByPhone}
                >
                  {authScreen.button.submit2}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col>
            <Row>
              <Col span={24}>
                <Row justify='center' className='mt-3'>
                  <Link to={ELECTRONIC_SIGNATURE_POLICY_PAGE}>{authScreen.electronicSignaturePolicy}</Link>
                </Row>
                <Row justify='center'>
                  <Link to={`${PERSONAL_DATA_POLICY_PAGE}${docParams}`}>{authScreen.personalDataPolicy}</Link>
                </Row>
                <Row justify='center'>
                  <Link to={PARTNERS_SBER_MARKET_POLICY_PAGE}>{authScreen.partnersSberMarketPolicy}</Link>
                </Row>
                <Row justify='center'>
                  <a href={personalPolicyDocLink} target='_blank' rel='noreferrer'>{authScreen.personalPolicy}</a>
                </Row>
              </Col>
            </Row>
          </Col>
          {!(!state.isModalPhone && (isAndroid || !isMobile())) && (
            <ModalDrawerPopup
              isOpen={state.isModalPhone}
              forceRender={!isAndroid}
              maskClosable
              // centered
              drawerHeight='auto'
              onClose={onCloseByPhone}
              className={`modalAuthPhone ${!state.isModalPhone && 'hide'}`}
            >
              <Spin spinning={isLoadingAuthData || isLoadingVerify} size='large'>
                <Form
                  form={form}
                  onFieldsChange={onFieldsChange}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Title level={4} style={{ textAlign: 'center' }}>{authScreen.titleModal}</Title>
                  {
                    state.inputMode === inputModes.inputPhone && (
                      <>
                        <div className='message'>Укажите номер, с которого будете принимать заказы</div>
                        <Form.Item
                          className='phone-input'
                          name='phoneNumber'
                          rules={[{
                            required: true,
                            message: authScreen.input.phoneNumber.message,
                            validator: validatePhone
                          }]}
                        >
                          <PatternFormat
                            onClick={handleClickByInputForScroll}
                            size='large'
                            format='+7 ### ###-##-##'
                            allowEmptyFormatting
                            mask='_'
                            value={state.mobilePhone}
                            placeholder={authScreen.input.phoneNumber.placeholder}
                            autoComplete
                            onChangeCapture={e => {
                              if (!e?.target?.value?.includes('+')) {
                                e.stopPropagation()
                                let phoneNumber = e?.target?.value
                                if (phoneNumber[0] === '8') {
                                  phoneNumber = e?.target?.value?.slice(1)
                                }
                                form.setFieldsValue({ phoneNumber })
                                setState({ mobilePhone: '+7' + phoneNumber })
                              }
                            }}
                            onChange={handleChange}
                            // disabled={disabled}
                            customInput={Input}
                            getInputRef={inputPhoneRef}
                          // allowClear
                          />
                        </Form.Item>
                        <Form.Item className='phone-btn'>
                          <Button
                            className='transparent-btn mobile-number-btn'
                            block
                            size='large'
                            type='primary'
                            disabled={state.timeState.time}
                            // loading={!!state.timeState.time}
                            onClick={onSendSmsCode}
                          >
                            {state.timeState.time ? authScreen.button.retryAfterShort + state.timeState.time : authScreen.button.submit1}
                          </Button>
                        </Form.Item>
                      </>
                    )
                  }

                  {/* { */}
                  {/*  state.inputMode === inputModes.inputSms && ( */}
                  <div className={`input-sms ${state.inputMode !== inputModes.inputSms && 'input-sms-hide'}`}>
                    <div className='message'>Отправили код на номер {state.mobilePhone}</div>
                    <Form.Item
                      className='phone-input'
                      name='smsCode'
                      rules={[{ required: true, message: authScreen.input.smsCode.message }]}
                      help={state.smsCodeError ? <Text type='danger'>{state.smsCodeError}</Text> : null}
                    >
                      {(isAndroid || !isMobile()) ? (
                        <Input
                          ref={inputSmsRef}
                          size='large'
                          type='number'
                          className={`noScrollNumber ${state.smsCodeError ? 'error' : ''}`}
                          placeholder={authScreen.input.smsCode.placeholder}
                        />
                      ) : (
                        <PatternFormat
                          size='large'
                          format='######'
                          allowEmptyFormatting
                          className={`noScrollNumber ${state.smsCodeError ? 'error' : ''}`}
                          placeholder={authScreen.input.smsCode.placeholder}
                          customInput={Input}
                          getInputRef={inputSmsRef}
                        />
                      )}
                    </Form.Item>
                    {
                      state.timeState.time && (
                        <div className='message'>{authScreen.button.retryAfter + state.timeState.time}</div>
                      )
                    }
                    {
                      !state.timeState.time && (
                        <Form.Item className='phone-btn'>
                          <Button
                            className='transparent-btn'
                            block
                            size='large'
                            type='primary'
                            onClick={onSendSmsCode}
                          >
                            Отправить повторно
                          </Button>
                        </Form.Item>
                      )
                    }
                  </div>
                  {/*  ) */}
                  {/* } */}
                </Form>
              </Spin>
            </ModalDrawerPopup>
          )}
          <ModalAuthUuid
            isModalOpen={state.isModalAuthUuid}
            onOk={() => setState({ isModalAuthUuid: false })}
          />
        </Content>
      </Layout>
    </Layout>
  )
}
export default AuthScreen
