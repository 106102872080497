import React from 'react'
import { isMobile } from '../../../helper'
import { Drawer, Modal } from 'antd'

function ModalDrawerPopup ({
  className = '',
  drawerClassName = '',
  modalClassName = '',
  isOpen,
  onClose = () => {},
  closable = false,
  maskClosable = false,
  destroyOnClose = false,
  forceRender = false,
  drawerHeight = 300,
  children
}) {
  return (
    <>
      {isMobile()
        ? (
          <Drawer
            className={`mobileDrawerModal ${className} ${drawerClassName}`}
            visible={isOpen}
            onClose={onClose}
            destroyOnClose={destroyOnClose}
            closable={closable}
            maskClosable={maskClosable}
            placement='bottom'
            height={drawerHeight}
            forceRender={forceRender}
          >
            {children}
          </Drawer>
        )
        : (
          <Modal
            className={`mobileDrawerModal ${className} ${modalClassName}`}
            visible={isOpen}
            onCancel={onClose}
            destroyOnClose={destroyOnClose}
            closable={closable}
            maskClosable={maskClosable}
            footer={null}
          >
            {children}
          </Modal>
        )}
    </>
  )
}

export default ModalDrawerPopup
