import React from 'react'
import { Typography } from 'antd'
import { profileScreen } from '../../../../translates'

const { Text } = Typography

function ElectronicLmkPhoto () {
  return (
    <>
      {/* <Title level={3}>Страница с голограммой</Title> */}
      <Text>
        {profileScreen.input.electronicLmk.hint}
      </Text>
      <br />
      <br />
      <img src='/img/photo-doc/medical-electronic.png' className='img-doc' />
    </>
  )
}

export default ElectronicLmkPhoto
