import React, { useContext, useEffect } from 'react'
import { Alert, Button, Col, Form, message, Row } from 'antd'
import { BankAccountFormItem, BankBikFormItem, BankNameFormItem } from '../../../Common'
import { useMutateConsentTinkoffBankDetails } from '../../../../api/react-query/partners'
import { useStores } from '../../../../Store/Store'
import { ConfigContext } from '../../../../context/configContext'
import './BankAcceptStep.css'

function BankAcceptStep () {
  const { setConfig } = useContext(ConfigContext)
  const { partnerProfile } = useStores()

  const {
    mutate: consentTinkoffBankDetails,
    data: dataTinkoffBankDetails,
    isSuccess: isSuccessTinkoffBankDetails,
    isError: isErrorTinkoffBankDetails,
    isLoading: isLoadingTinkoffBankDetails
  } = useMutateConsentTinkoffBankDetails()

  useEffect(() => {
    if (isSuccessTinkoffBankDetails && dataTinkoffBankDetails?.isSuccess) {
      message.success('Данные успешно сохранены')
      partnerProfile.setPartnerProfile({
        ...dataTinkoffBankDetails,
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
    } else if (isErrorTinkoffBankDetails || dataTinkoffBankDetails?.errorMessage) {
      message.error(dataTinkoffBankDetails?.errorMessage || 'Ошибка передачи данных БР')
    }
  }, [dataTinkoffBankDetails, isSuccessTinkoffBankDetails, isErrorTinkoffBankDetails])

  useEffect(() => {
    setConfig({
      loadingData: {
        isLoadingFlowV2: isLoadingTinkoffBankDetails,
        loadingText: <>Сохраняем данные.<br />Это может занять какое-то время.</>
      }
    })
  }, [isLoadingTinkoffBankDetails])

  const handleAccept = () => {
    sendData(true)
  }

  const handleDecline = () => {
    sendData(false)
  }

  const sendData = (isAccepted) => {
    consentTinkoffBankDetails({
      isAccepted,
      ...(isAccepted
        ? {
          bankInfo: {
            bankName: partnerProfile?.profile?.tinkoffData?.bankDetails.bankName,
            bankAccount: partnerProfile?.profile?.tinkoffData?.bankDetails.bankAccount,
            bankBik: partnerProfile?.profile?.tinkoffData?.bankDetails.bankBik,
            name: partnerProfile?.profile?.tinkoffData?.bankDetails.name
          }
        }
        : {}
      )
    })
  }

  return (
    <Row justify='space-between' className='flex-column bank-accept-step'>
      <Col>
        <Alert
          style={{ marginBottom: '2rem' }}
          message={<b>Используйте реквизиты из Tinkoff ID</b>}
          description={
            <>
              На этот счёт будет приходить еженедельная выплата.
              Изменить его или указать реквизиты другого банка сможете в любой момент в Шоппере
            </>
          }
          type='warning'
          showIcon
        />
        <BankNameFormItem disabled />
        <BankAccountFormItem disabled />
        <BankBikFormItem disabled />
      </Col>
      <Col span={24}>
        <Form.Item>
          <Button
            block
            size='large'
            type='success'
            onClick={handleAccept}
          >
            Использовать эти реквизиты
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            block
            size='large'
            type='default'
            onClick={handleDecline}
          >
            Заполнить позже вручную
          </Button>
        </Form.Item>
      </Col>
    </Row>
  )
}

export default BankAcceptStep
