import React, { useEffect } from 'react'
import { Button, Col, List, Tooltip, Modal, message } from 'antd'
import moment from 'moment-timezone'
import { CheckCircleTwoTone, CloseOutlined, CloseSquareTwoTone, ExclamationCircleFilled } from '@ant-design/icons'

import { AppConfig } from '../../../AppConfig'
import { sortByParam } from '../../../helper'
import { useMutateVerificationFree } from '../../../api/react-query/adminPartners'
const { confirm } = Modal

const countCity = 2
const coordinatorGroups = [AppConfig.groups.externalcoordinators, AppConfig.groups.coordinators]
const coordinatorLabels = {
  [AppConfig.groups.externalcoordinators]: 'Внешний координатор',
  [AppConfig.groups.coordinators]: 'Координатор'
}

function CoordinatorItem ({ item, cityButton = null, onClickPriority }) {
  const getPriorityCities = () => {
    return sortByParam(item?.priorityCities, 'cityName')?.slice(0, countCity)?.map(city => city?.cityName).join(', ')
  }

  const {
    mutate: mutateVerificationFree,
    isSuccess: isSuccessVerificationFree,
    isError: isErrorVerificationFree
  } = useMutateVerificationFree()

  useEffect(() => {
    if (isSuccessVerificationFree) {
      message.success('Координатор отправлен в офлайн')
    } else if (isErrorVerificationFree) {
      message.error('Ошибка отправки координатора в офлайн')
    }
  }, [isSuccessVerificationFree, isErrorVerificationFree])

  const handleSendToOffline = () => {
    confirm({
      title: 'Отправить координатора в офлайн',
      icon: <ExclamationCircleFilled />,
      okText: 'Да',
      cancelText: 'Отмена',
      onOk: () => mutateVerificationFree(item?.userId)
    })
  }

  return (
    <List.Item>
      <Col span={4}>
        {item?.userName}
        {coordinatorGroups.includes(item?.userGroup) && (<><br />({coordinatorLabels[item?.userGroup]})</>)}
      </Col>
      <Col span={4}>
        {
          item?.loginTimestamp
            ? moment(item?.loginTimestamp).format(AppConfig.formats.shortDateAndTime)
            : ''
        }
      </Col>
      <Col span={5}>
        {
          item?.isOnline
            ? <CheckCircleTwoTone twoToneColor='#00AF64' className='iconTime' />
            : <CloseSquareTwoTone twoToneColor='red' className='iconTime' />
        }
        {
          item?.isOnlineTimestamp
            ? moment(item?.isOnlineTimestamp).format(AppConfig.formats.shortDateAndTime)
            : ''
        }
      </Col>
      <Col span={6}>
        {!item?.priorityCities?.length && 'не задано '}
        {item?.priorityCities?.length > 0 && getPriorityCities()}
        {item?.priorityCities?.length > countCity && '...'}
        {' '}
        {cityButton !== null && cityButton}
      </Col>
      <Col span={3}>
        <div>
          {item?.partnerVerificationSelector === AppConfig.coordinatorTakePriority.new
            ? <CheckCircleTwoTone twoToneColor='#00AF64' className='iconTime' />
            : <span className='emptyIcon' />}
          {item?.partnerVerificationSelector === AppConfig.coordinatorTakePriority.new
            ? <>Новая</>
            : <a onClick={() => onClickPriority(item, AppConfig.coordinatorTakePriority.new)}>Новая</a>}
        </div>
        <div>
          {item?.partnerVerificationSelector === AppConfig.coordinatorTakePriority.old
            ? <CheckCircleTwoTone twoToneColor='#00AF64' className='iconTime' />
            : <span className='emptyIcon' />}
          {item?.partnerVerificationSelector === AppConfig.coordinatorTakePriority.old
            ? <>Старая</>
            : <a onClick={() => onClickPriority(item, AppConfig.coordinatorTakePriority.old)}>Старая</a>}
        </div>
        {
          item?.userGroup !== AppConfig.groups.externalcoordinators && (
            <div>
              {item?.partnerVerificationSelector === AppConfig.coordinatorTakePriority.bankRequisite
                ? <CheckCircleTwoTone twoToneColor='#00AF64' className='iconTime' />
                : <span className='emptyIcon' />}
              {item?.partnerVerificationSelector === AppConfig.coordinatorTakePriority.bankRequisite
                ? <>Анкета</>
                : <a onClick={() => onClickPriority(item, AppConfig.coordinatorTakePriority.bankRequisite)}>Анкета</a>}
            </div>
          )
        }
      </Col>
      <Col span={2}>
        <Tooltip title='Отправить в офлайн'>
          <Button type='default' shape='circle' className='ml-3' icon={<CloseOutlined />} onClick={handleSendToOffline} />
        </Tooltip>
      </Col>
    </List.Item>
  )
}

export default CoordinatorItem
